import React, { useCallback, useEffect, useState } from "react";

import { locations, formatAddress } from "../../util/locations";
import settings from "../../util/settings";
import { detectLocationUsingGPS } from "../../hooks/useLocationDetection";
import styles from "./location-picker.module.scss";

const LocationPicker = ({
  currentLocation,
  updateLocation,
  setLocationPickingInProgress,
}) => {
  const [detectingLocation, setDetectingLocation] = useState(false);
  const [locationDetectionError, setLocationDetectionError] = useState();

  useEffect(() => {
    setLocationPickingInProgress(true);
    return () => {
      setLocationPickingInProgress(false);
    };
  }, [setLocationPickingInProgress]);

  const autoDetectCTAHandler = useCallback(() => {
    detectLocationUsingGPS(
      (location) => {
        setDetectingLocation(false);
        updateLocation(location);
      },
      (error) => {
        setDetectingLocation(false);
        if (error && error.code === 1) {
          setLocationDetectionError(
            "Please allow location permissions so we can detect the closest location to you."
          );
        }
      }
    );
    setDetectingLocation(true);
  }, []);

  return (
    <>
      <div className={styles["background-overlay"]}></div>
      <div className={styles["location-picker"]}>
        <div className={styles["location-picker-header"]}>
          <img
            alt="Brand logo"
            className={styles["brand-logo"]}
            src={settings.ASSET_PATH + "svg/kudosgolf-brand.svg"}
          />
          {currentLocation && (
            <button
              onClick={() => updateLocation(currentLocation)}
              className={styles["close-button"]}
              disabled={detectingLocation}
            >
              <img
                alt="loading icon"
                src={settings.ASSET_PATH + "svg/close-icon.svg"}
              />
            </button>
          )}
        </div>
        <div className={styles["location-list-container"]}>
          <h3 className={styles["heading"]}>Choose your preferred location</h3>
          <ul className={styles["location-list"]}>
            {locations.map((location, index) => {
              const { city, postalCode, province, street } = formatAddress(
                location.address
              );
              return (
                <li
                  key={"location-" + index}
                  onClick={() => updateLocation(location)}
                  className={detectingLocation ? styles["disabled"] : ""}
                >
                  <p className={styles["location-name"]}>{location.name}</p>
                  <p className={styles["location-address"]}>
                    {street},<br />
                    {city}, {province}
                    <br />
                    {postalCode}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <p style={{ marginBottom: 0 }}>Scroll down for more options</p>
        {locationDetectionError ? (
          <p>{locationDetectionError}</p>
        ) : (
          <a
            onClick={autoDetectCTAHandler}
            className={`text-link ${styles["auto-detect-location-cta"]}`}
          >
            {detectingLocation ? (
              <>
                Finding nearest location
                <img
                  alt="loading icon"
                  className={styles["loading-icon"]}
                  src={settings.ASSET_PATH + "svg/loader.svg"}
                />
              </>
            ) : (
              "Choose the location nearest to me"
            )}
          </a>
        )}
      </div>
    </>
  );
};

export default LocationPicker;
