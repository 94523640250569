import locations from './locations'

const LOCATION_PREFERENCE_KEY = "preferred-location";

export const saveLocationPreference = (location) => {
  if (location)
    localStorage.setItem(
      LOCATION_PREFERENCE_KEY,
      JSON.stringify({ id: location.id, timestamp: Number(new Date()) })
    );
};

export const getLocationPreference = () => {
  let item = localStorage.getItem(LOCATION_PREFERENCE_KEY);

  // if the item doesn't exist, return null
  if (!item) return null;

  item = JSON.parse(item);
  return locations.find(loc => loc.id === item.id)
};