import React, {
  useRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { gsap, Power3 } from "gsap";
import Link from "next/link";
import CTA from "../CTA/cta.module";
import settings from "../../util/settings";
import Context from "../context/context";
import Picture from "../picture/picture.module";
import styles from "./nav.module.scss";
import analytics from "../../util/analytics";
import makePath from "../../util/make-path";
import { DEFAULT_LOCATION, formatAddress } from "../../util/locations";
import trackBookingEvent from "../../util/conversion-events/booking-event";

let analyticsObj;
const ActionButton = ({ onClick }) => {
  const { location } = useContext(Context);
  const locationToUse = useMemo(
    () => (location ? location : DEFAULT_LOCATION),
    [location]
  );
  return (
    <CTA
      href={locationToUse.endpoints.SCHEDULER}
      onClick={(e) => {
        trackBookingEvent();
        if (onClick) onClick();
      }}
      className={`button ${styles["booking-cta"]}`}
      text="Book a session"
      analytics={analyticsObj}
      src="/"
      dest="booking-system"
    />
  );
};

const PortalLogin = ({ responsive }) => (
  <CTA
    href="https://portal.e6golf.com/login"
    target="_blank"
    className={`${
      responsive ? styles["nav-link-responsive"] : styles["nav-link"]
    } ${styles["portal-login-cta"]}`}
    text="Player Profile"
    analytics={analyticsObj}
    src="/"
    dest="e6-portal-login"
  />
);

const LocationPickButton = ({ location, openLocationPicker }) => {
  return (
    <button
      className={styles["location-pick-button"]}
      onClick={() => openLocationPicker()}
    >
      {location ? location.name : "Select Location"}
      <Picture
        src={settings.ASSET_PATH + "images/components/location.png"}
        className={styles["location-pick-icon"]}
        alt="Location pin icon"
      />
    </button>
  );
};

const pages = [
  {
    name: "About Us",
    url: "/about-us",
  },
  {
    name: "How It Works",
    url: "/how-it-works",
  },
  // {
  //   name: "Coaching",
  //   url: "/coaching",
  // },
  {
    name: "Pricing",
    url: "/pricing",
  },
  {
    name: "Contact Us",
    url: "/contact-us",
  },
];

const MIN_SCREEN_WIDTH = 1380;

const isResponsive = () =>
  !settings.isServer() && window.innerWidth < MIN_SCREEN_WIDTH;

const Nav = ({ openLocationPicker }) => {
  const [responsive, setResponsive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { location, locationPickingInProgress } = useContext(Context);

  const animOnRef = useRef(false);
  const responsiveContainerRef = useRef(false);
  const line1Ref = useRef();
  const line2Ref = useRef();

  const navIconRef = useRef();
  const navLinkContainerRef = useRef();

  const toggleMenu = useCallback(
    (force) => {
      if (animOnRef.current || !responsive) return;
      const tl = gsap.timeline({
        onComplete: () => {
          if (expanded) {
            document.body.style.overflow = "auto";
          }
          animOnRef.current = false;
        },
      });
      const time = 0.25;

      animOnRef.current = true;
      if (!expanded && (!force || force === "open")) {
        document.body.style.overflow = "hidden";

        tl.set([line1Ref.current, line2Ref.current], {
          transformOrigin: "19px",
        });

        tl.to(line1Ref.current, { y: 5, duration: time });
        tl.to(line2Ref.current, { y: -5, duration: time, delay: -time / 2 });

        tl.to([line1Ref.current, line2Ref.current], {
          rotateZ: "90deg",
          duration: time / 2,
        });

        tl.to(line1Ref.current, { rotateZ: "45deg", duration: time / 2 });
        tl.to(line2Ref.current, {
          rotateZ: "135deg",
          delay: -time / 2,
          duration: time / 2,
        });

        setTimeout(() => {
          setExpanded(true);
        }, time * 2000);
      } else if (expanded && (!force || force === "close")) {
        tl.set([line1Ref.current, line2Ref.current], {
          transformOrigin: "19px",
        });

        tl.to(line1Ref.current, { rotateZ: "0deg", duration: time / 2 });
        tl.to(line2Ref.current, {
          rotateZ: "0deg",
          delay: -time / 2,
          duration: time / 2,
        });

        tl.to(line1Ref.current, { y: 0, duration: time });
        tl.to(line2Ref.current, { y: 0, duration: time, delay: -time / 2 });

        tl.to(responsiveContainerRef.current, {
          autoAlpha: 0,
          duration: time,
          delay: -time,
          onComplete: () => {
            setExpanded(false);
          },
        });
      }
    },
    [responsive, expanded]
  );

  const getLink = useCallback(
    (page = "", index, responsive) => {
      if (page) {
        let classes =
          !settings.isServer() && window.location.pathname === page.url
            ? styles["active"]
            : "";
        classes +=
          " " +
          (responsive ? styles["nav-link-responsive"] : styles["nav-link"]);

        return (
          <Fragment key={index}>
            <Link href={page.url}>
              <a className={classes} onClick={() => toggleMenu("close")}>
                {page.name}
              </a>
            </Link>
          </Fragment>
        );
      }
    },
    [responsive, toggleMenu]
  );

  const updateResponsiveStateIfDifferent = useCallback(() => {
    const responsiveNew = isResponsive();
    if (responsive !== responsiveNew) {
      if (!settings.isServer()) {
        if (!responsiveNew) {
          document.body.style.overflow = "auto";
        } else if (responsiveNew && expanded) {
          document.body.style.overflow = "hidden";
        }
      }

      setResponsive(responsiveNew);
    }
  }, [responsive, expanded]);

  useEffect(() => {
    window.addEventListener("resize", updateResponsiveStateIfDifferent);
    updateResponsiveStateIfDifferent();

    if (!settings.isServer()) {
      analyticsObj = analytics.setup();
    }
    return () => {
      window.removeEventListener("resize", updateResponsiveStateIfDifferent);
    };
  }, []);

  useEffect(() => {
    window.removeEventListener("resize", updateResponsiveStateIfDifferent);
    window.addEventListener("resize", updateResponsiveStateIfDifferent);
  }, [updateResponsiveStateIfDifferent]);

  useEffect(() => {
    const itemsToHide = [
      navIconRef.current,
      navLinkContainerRef.current,
      responsiveContainerRef.current,
    ];
    gsap.to(
      itemsToHide.filter((ref) => {
        if (ref) return true;
      }),
      {
        duration: 0.25,
        autoAlpha: locationPickingInProgress ? 0 : 1,
      }
    );
  }, [locationPickingInProgress]);

  return (
    <nav className={`navbar sticky-top ${styles["nav-module"]}`}>
      <div className={`section-content ${styles["section-sticky"]}`}>
        <div className={styles["brand-logo-container"]}>
          <Link href="/">
            <a
              className={`${styles["brand-logo"]}`}
              onClick={() => toggleMenu("close")}
            >
              <img
                alt="Brand logo"
                className={styles["menu-logo"]}
                src={settings.ASSET_PATH + "svg/kudosgolf-brand.svg"}
              />
            </a>
          </Link>
        </div>
        {responsive ? (
          <div
            className={styles["nav-toggle-container"]}
            key={"nav-toggle-container"}
            ref={navIconRef}
          >
            <LocationPickButton
              location={location}
              openLocationPicker={openLocationPicker}
            />
            <svg
              viewBox="0 0 40 40"
              className={styles["toggle-icon"]}
              onClick={() => toggleMenu()}
            >
              <line
                x1={1}
                y1={15}
                x2={39}
                y2={15}
                ref={line1Ref}
                stroke="red"
              />
              <line x1={1} y1={25} x2={39} y2={25} ref={line2Ref} />
            </svg>
          </div>
        ) : (
          <div
            className={styles["nav-link-container"]}
            key={"nav-link-container"}
            ref={navLinkContainerRef}
          >
            <div className={styles["float-left"]}>
              {pages.map((item, index) => getLink(item, index))}
              <PortalLogin responsive={responsive} />
            </div>

            <div className={styles["float-right"]}>
              <LocationPickButton
                location={location}
                openLocationPicker={openLocationPicker}
              />
              <ActionButton />
            </div>
          </div>
        )}
      </div>

      {responsive && expanded && (
        <div
          className={`section-content ${styles["nav-link-container-responsive"]}`}
          ref={responsiveContainerRef}
        >
          {pages.map((item, index) => getLink(item, index, true))}
          <PortalLogin responsive={responsive} />
          <div className={styles["action-button-responsive"]}>
            <ActionButton onClick={() => toggleMenu("close")} />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;
