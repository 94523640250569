import { useContext } from 'react';

import Context from "../context/context";
import styles from './banner.module.scss'

const Banner = () => {
  const { location } = useContext(Context);
  return location?.banner ? (
    <div className={`${styles['banner']} section-content`}>
      <p className={'text-body-medium ' + styles['banner-msg']} dangerouslySetInnerHTML={{ __html: location.banner }} />
    </div>
  ) : null
};

export default Banner;